<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tim.certificate') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <searchBasic :search="search" @search-data="searchIt"/>
              <b-col lg="12" sm="12" class="text-right">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.certificate_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="certificate-generate-form" :class="'btn btn_add_new'">
                        <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                      </router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(designation)="data">
                                        <span v-if="data.item.designation_id && data.item.not_here_designation == 0 && data.item.profession_type == 1">{{ getDesignation(data.item.designation_id) }}</span>
                                        <span v-else>{{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en}}</span>
                                        </template>
                                        <template v-slot:cell(work_place)="data">
                                          <span v-if="data.item.profession_type == 1 && data.item.not_here_office == 0">{{ getOfficeName(data.item.officeId) }}</span>
                                          <span v-else-if="data.item.profession_type == 1 && data.item.not_here_office == 1">{{ (($i18n.locale=='bn') ? data.item.other_office_name_bn : data.item.other_office_name) }}</span>
                                          <span v-else>{{ ($i18n.locale=='bn') ? data.item.other_office_name_bn : data.item.other_office_name }}</span>
                                          <!-- <span v-if="data.item.professional_info.office_id">{{ getOfficeName(data.item.professional_info.office_id) }}</span> -->
                                        </template>
                                        <template v-slot:cell(email)="data">
                                        <span class="capitalize">{{ data.item.email }}</span>
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                            {{ data.item.mobile |mobileNumber }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <router-link v-if="data.item.circular_org_id === 4" @click.native="remove(data.item)" :to="`/training-e-learning-service/tim/barc-certificate?id=${data.item.id}&circular_memo_no=${data.item.circular_memo_no}`" title="barc View" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                          <router-link v-if="data.item.circular_org_id === 3" @click.native="remove(data.item)" :to="`/training-e-learning-service/tim/badc-certificate?id=${data.item.id}&circular_memo_no=${data.item.circular_memo_no}`" title="badc View" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                          <router-link v-if="data.item.circular_org_id === 12" @click.native="remove(data.item)" :to="`/training-e-learning-service/tim/certificate?id=${data.item.id}&circular_memo_no=${data.item.circular_memo_no}`" title="View" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                          <router-link v-if="data.item.circular_org_id === 19" @click.native="remove(data.item)" :to="`/training-e-learning-service/tim/bwmri-certificate?id=${data.item.id}&circular_memo_no=${data.item.circular_memo_no}`" title="View" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                          <!-- <router-link v-if="data.item.circular_org_id === 19" @click.native="remove(data.item)" :to="`/training-e-learning-service/tim/certificate?id=${data.item.id}&circular_memo_no=${data.item.circular_memo_no}`" title="View" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link> -->
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
        <b-modal id="modal-6" size="lg" :title="$t('globalTrans.description')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <Description :item="item" :key="item.id"/>
        </p>
    </b-modal>
    <!-- <pre>{{listData}}</pre> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { certificateIndividualList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
import Description from './Description'
import Store from '@/store'
import searchBasic from './../../../component/searchBasic'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details, Description, searchBasic
    },
    data () {
      return {
        circularLoading: false,
        search: {
          coordinator_id: 0,
          org_id: null,
          fiscal_year_id: 0,
          circular_memo_no: 0,
          training_type_id: null,
          training_category_id: null,
          training_title_id: null,
          batch_no: 0
        },
        item: [],
        trainingCategoryList: [],
        trainingTitleList: [],
        circularList: [],
        allBatchListData: [],
        trainingTypeList: [],
        batchList: []
      }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('elearning_config.update')
      },
      userTypeList () {
        const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              // { label: this.$t('elearning_iabm.registration_for'), class: 'text-left' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              // { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('elearning_config.training_title'), class: 'text-left' },
              { label: this.$t('elearning_tim.name'), class: 'text-left' },
              { label: this.$t('globalTrans.designation'), class: 'text-left' },
              { label: this.$t('elearning_tpm.work_place'), class: 'text-left' },
              { label: this.$t('globalUserData.mobile_no'), class: 'text-left' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'index' },
                // { key: 'user_type_name_bn' },
                { key: 'fiscal_year_bn' },
                { key: 'circular_memo_no' },
                // { key: 'org_bn' },
                { key: 'training_title_bn' },
                { key: 'name_bn' },
                { key: 'designation' },
                { key: 'work_place' },
                { key: 'mobile' },
                { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'index' },
                // { key: 'user_type_name' },
                { key: 'fiscal_year' },
                { key: 'circular_memo_no' },
                // { key: 'org' },
                { key: 'training_title' },
                { key: 'name' },
                { key: 'designation' },
                { key: 'work_place' },
                { key: 'mobile' },
                { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
          fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
      this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
    },
    methods: {
      getOrgName (id) {
          const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
              return trainingType !== undefined ? trainingType.text_bn : ''
          } else {
              return trainingType !== undefined ? trainingType.text_en : ''
          }
      },
      getDesignation (id) {
        const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return designation !== undefined ? designation.text_bn : ''
        } else {
          return designation !== undefined ? designation.text_en : ''
        }
      },
      getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return office !== undefined ? office.text_bn : ''
        } else {
          return office !== undefined ? office.text_en : ''
        }
      },
      searchIt (search) {
        this.search = search
      },
      remove (data) {
        localStorage.setItem('DataToGet', JSON.stringify(data))
      },
       note (item) {
        this.item = item
       },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, certificateIndividualList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const organizationObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.orgid))
          const organizationData = {}
          if (typeof organizationObj !== 'undefined') {
            organizationData.organization = organizationObj.text_en
            organizationData.organization_bn = organizationObj.text_bn
          } else {
            organizationData.organization = ''
            organizationData.organization_bn = ''
          }
          const headingObj = this.$store.state.TrainingElearning.commonObj.trainingReportHeadList.find(doc => doc.org_id === parseInt(item.orgid))
          const headingData = {}
          if (typeof headingObj !== 'undefined') {
            headingData.address = headingObj.address
            organizationData.address_bn = headingObj.address_bn
          } else {
            headingData.address = ''
            headingData.address_bn = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          return Object.assign({}, item, orgData, organizationData, headingData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, customItem)
        })
        return listData
      }
    }
}
</script>
<style>
.wid span {
    display: block;
    width: 100px;
    overflow-wrap: anywhere;
}
</style>
