<template>
  <b-row>
      <b-col lg="6" sm="12">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="fiscal_year_id"
        >
          <template v-slot:label>
            {{$t('globalTrans.fiscal_year')}}
          </template>
          <!-- <b-form-select
            plain
            v-model="search.fiscal_year_id"
            :options="fiscalYearList"
            id="fiscal_year_id"
            @change="dataReturn"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select> -->
                      <v-select name="fiscal_year_id"
                        v-model="search.fiscal_year_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= fiscalYearList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
        </b-form-group>
      </b-col>
      <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="org_id"
        >
          <template v-slot:label>
            {{$t('elearning_config.organization')}}
          </template>
          <!-- <b-form-select
            plain
            v-model="search.org_id"
            :options="orgList"
            id="org_id"
            @change="dataReturn"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select> -->
                      <v-select name="org_id"
                        v-model="search.org_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= orgList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
        </b-form-group>
      </b-col>
      <b-col lg="6" sm="12">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="training_type_id"
        >
          <template v-slot:label>
            {{$t('elearning_config.training_type')}}
          </template>
          <!-- <b-form-select
            plain
            v-model="search.training_type_id"
            :options="trainingTypeList"
            id="training_type_id"
            @change="dataReturn"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select> -->
                      <v-select name="training_type_id"
                        v-model="search.training_type_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= trainingTypeList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
        </b-form-group>
      </b-col>
      <b-col lg="6" sm="12">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="training_category_id"
          >
            <template v-slot:label>
              {{$t('elearning_config.training_category')}}
            </template>
            <!-- <b-form-select
              plain
              v-model="search.training_category_id"
              :options="trainingCategoryList"
              id="training_category_id"
              @change="dataReturn"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select> -->
                      <v-select name="training_category_id"
                        v-model="search.training_category_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= trainingCategoryList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
        </b-form-group>
      </b-col>
      <b-col lg="6" sm="12">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="training_title_id"
          >
            <template v-slot:label>
              {{$t('elearning_config.training_title')}}
            </template>
            <!-- <b-form-select
              plain
              v-model="search.training_title_id"
              :options="trainingTitleList"
              id="training_title_id"
              @change="dataReturn"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select> -->
                      <v-select name="training_title_id"
                        v-model="search.training_title_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= trainingTitleList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
        </b-form-group>
      </b-col>
      <b-col lg="6" sm="12">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="batch_no"
        >
          <template v-slot:label>
            {{$t('elearning_iabm.batch_no')}}
          </template>
          <b-form-select
            plain
            v-model="search.batch_no"
            :options="batchList"
            @change="dataReturn"
            id="batch_no"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
            </template>
            </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" sm="12">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="circular_memo_no"
        >
          <template v-slot:label>
            {{$t('elearning_iabm.circular_memo_no')}}
          </template>
          <b-form-select
            plain
            v-model="search.circular_memo_no"
            :options="circularList"
            id="circular_memo_no"
            @change="dataReturn"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
            </template>
            </b-form-select>
        </b-form-group>
      </b-col>
  </b-row>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['search'],
  data () {
    return {
      circularLoading: false,
      trainingCategoryList: [],
      trainingTitleList: [],
      trainerNameList: [],
      circularList: [],
      allBatchListData: [],
      trainingTypeList: [],
      batchList: [],
      myFilter: (option, text, search) => {
                  const temp = search.toLowerCase()
                  return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                  option.text_bn.toLowerCase().indexOf(temp) > -1
              }
    }
  },
  async created () {
    this.search.fiscal_year_id = this.$store.state.TrainingElearning.currentFiscalYearId
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
      this.trainingTypeList = this.getTypeList(this.search.org_id)
    }
    if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
      this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
    }
  },
  computed: {
    allBatchList () {
      const batchList = [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
      return batchList
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('elearning_config.update')
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
            { label: this.$t('elearning_config.organization'), class: 'text-center' },
            { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
            { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
            { label: this.$t('elearning_config.training_type'), class: 'text-center' },
            { label: this.$t('elearning_config.training_category'), class: 'text-center' },
            { label: this.$t('elearning_config.training_title'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'org_name_bn' },
              { key: 'circular_memo_no' },
              { key: 'batch_name_bn' },
              { key: 'training_type_bn' },
              { key: 'training_category_bn' },
              { key: 'training_title_bn' },
              { key: 'action' }
            ]
        } else {
            keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'org_name' },
              { key: 'circular_memo_no' },
              { key: 'batch_name' },
              { key: 'training_type' },
              { key: 'training_category' },
              { key: 'training_title' },
              { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.circular_memo_no = ''
        this.search.batch_no = 0
        this.getCircularList()
      }
    },
    'search.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.training_type_id = null
        this.trainingTypeList = this.getTypeList(newValue)
      } else {
        this.trainingTypeList = []
      }
    },
    'search.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.training_category_id = null
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'search.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.training_title_id = null
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'search.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.circular_memo_no = ''
        this.search.batch_no = 0
        this.getCircularList()
      }
    },
    'search.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.search.batch_no = allBatchListData.batch_no
        }
      }
    },
    'search.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.search.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    }
  },
  methods: {
    getTypeList (orgId) {
      if (this.search.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.search.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      if (this.search.fiscal_year_id && this.search.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          training_type_id: this.search.training_type_id,
          training_category_id: this.search.training_category_id,
          training_title_id: this.search.training_title_id,
          coordinator_id: this.search.coordinator_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/circular-publication-list' + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    getCategoryList (typeId) {
      if (this.search.training_type_id) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      if (this.search.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      }
    },
    edit (item) {
      this.editItemId = item.id
    },
    dataReturn () {
      this.$emit('search-data', this.search)
    }
  }
}
</script>
