<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="certificate-generate-list" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tim.released_order') }} {{ $t('globalTrans.list') }}</router-link>
            <!-- <b-button variant="primary" @click="pdfExport" class="">
                {{  $t('globalTrans.export_pdf') }}
            </b-button> -->
            <b-button type="button" variant="primary" class="mr-2" @click="printID">{{ $t('globalTrans.print') }}</b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <div id="print-area">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                          {{ $t('elearning_tim.released_order') + ' ' + $t('globalTrans.details') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="top-height m-0" style="padding-left: 10px; padding-right: 10px">
                    <b-table-simple class="mt-3" hover small caption-top style="width:100%">
                      <tbody>
                        <tr>
                          <th style="width: 20%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                          <th class="text-center" style="width: 5%">:</th>
                          <td style="width: 25%" class="text-left">{{ honorariumSheet.circular_memo_no }}</td>
                          <th style="width: 20%" class="text-left" >{{ $t('globalTrans.date') }}</th>
                          <th class="text-center" style="width: 5%">:</th>
                          <td style="width: 25%" class="text-left">{{ releasedate.training_end_date | dateFormat }} {{ $t('elearning_tim.ad') }}</td>
                        </tr>
                      </tbody>
                    </b-table-simple>
                    <template>
                        <b>{{ releasedate.training_start_date | dateFormat }}</b> {{ $t('elearning_tim.release1') }} <b>{{ releasedate.training_end_date | dateFormat }}</b> {{ $t('elearning_tim.release2') }}
                        <b>“{{ $i18n.locale === 'bn' ? honorariumSheet.training_title_bn : honorariumSheet.training_title }}”</b> {{ $t('elearning_tim.release3') }} <b>{{ releasedate.training_end_date | dateFormat }}</b> {{ $t('elearning_tim.release4') }}
                    </template>
                  </b-col>
                </b-row>
                <b-overlay :show="load">
                  <b-row id="form" class="pt-4">
                    <div class="col-md-12">
                      <!-- <fieldset class="p-2 w-100"> -->
                        <!-- <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.details')}}</legend> -->
                        <br/>
                        <div class="table-responsive borders">
                          <table class="table tg" style="width:100%;" border="1">
                            <thead class="thead">
                              <tr>
                                <th class="text-center tg-0lax">{{$t('globalTrans.sl_no')}}</th>
                                <th class="text-center tg-0lax">{{$t('globalTrans.name')}}</th>
                                <th class="text-center tg-0lax">{{$t('globalTrans.mobile')}}</th>
                                <th class="text-center tg-0lax">{{$t('globalTrans.designation')}}</th>
                                <th class="text-center ">{{$t('elearning_tim.posting_office')}}</th>
                              </tr>
                            </thead>
                            <tr v-for="(data, index) in dataList" :key="index">
                              <td class="text-center tg-0lax" >
                                {{ $n(index + 1) }}
                              </td>
                              <td class="text-center tg-0lax">
                                {{ $i18n.locale === 'bn' ? data.name_bn : data.name }}
                              </td>
                              <td class="text-center tg-0lax">
                                {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.mobile, { useGrouping: false }) }}
                              </td>
                              <td class="text-center tg-0lax">
                                {{ ($i18n.locale==='bn') ? data.designation_name_bn : data.designation_name }}
                              </td>
                              <td class="text-center tg-0lax">
                                {{ (($i18n.locale==='bn') ? data.office_name_bn : data.office_name) + ', ' + (($i18n.locale==='bn') ? data.org_name_bn : data.org_name) }}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <br/>
                      <!-- </fieldset> -->
                    </div>
                  </b-row>
                  <template>
                      <table class="table" style="width:100%;">
                          <tr>
                              <td style="width:70%"></td>
                              <td style="width:30%; text-align:center">
                                  <span v-html="releaseDescription.personal_info"></span>
                              </td>
                          </tr>
                      <!-- <div class="row col-lg-12">
                          <div class="col-lg-8"></div>
                          <div class="col-lg-4 text-center">
                            <span v-html="releaseDescription.personal_info"></span>
                          </div>
                      </div> -->
                      </table>
                  </template>
                  <template>
                      <span v-html="releaseDescription.description"></span>
                  </template>
                </b-overlay>
              </div>
            </b-overlay>
          </template>
        </iq-card>
        <!-- <pre>{{trainees}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTrainingPr.vue'
import ExportPdf from './export_pdf_details'
import { certificateDetails } from '../../api/routes'

export default {
    components: {
        ListReportHead
    },
    created () {
        if (this.$route.query.circilarMemoNo) {
            this.getCircularMemoNo(this.$route.query.circilarMemoNo)
        }
        // this.getMaterils(this.honorariumSheet.training_title_id)
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      load: false,
      officeTypeList: [],
      honorariumSheet: [],
      materials: [],
      items: [],
      data: [],
      dataList: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      releaseDescription: [],
      releasedate: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      personalInfoList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async getCircularMemoNo (circularMemoNo) {
      if (circularMemoNo) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateDetails, circularMemoNo)
        if (!result.success) {
          this.honorariumSheet = []
        } else {
            this.honorariumSheet = result.data[0]
            this.releaseDescription = result.dataMain
            this.releasedate = result.datapublication
            this.personalInfoList = result.personalInfoList
            this.getCustomDataZeroList(result.data[0])
            this.dataList = this.getCustomDataList(result.data)
            // const trainees = result.data.filter(trainee => trainee.payment_type === 'Trainee')
            // this.trainees = this.getCustomDataList(trainees)
            // this.materials = result.matherial
        }
        this.load = false
      }
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.released_order') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.honorariumSheet, this, this.dataList, this.releaseDescription, this.releasedate)
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
          const personalObj = this.personalInfoList.find(doc => doc.value === parseInt(item.personal_info_id))
          const personalData = {}
          if (typeof personalObj !== 'undefined') {
            personalData.name = personalObj.text_en
            personalData.name_bn = personalObj.text_bn
          } else {
            personalData.name = ''
            personalData.name_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_name = officeObj.text_en
              officeData.office_name_bn = officeObj.text_bn
            } else {
              officeData.office_name = ''
              officeData.office_name_bn = ''
            }
          return Object.assign({}, item, personalData, orgData, desigData, officeData)
        })
        return listData
    },
    printID () {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print-area').innerHTML

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" media="print">
         <style>
           @media print {
              .row::after {
                  clear: both;
              }
              .row::before {
                  display: table;
                  content: " ";
              }
              .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
            .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md-4, col-md-8 {
                 float: left;
            }

            .col-lg-12 {
                 width: 100%;
            }

            .col-lg-11 {
                 width: 91.66666666666666%;
            }

            .col-lg-10 {
                 width: 83.33333333333334%;
            }

            .col-lg-9 {
                  width: 75%;
            }

            .col-lg-8 {
                  width: 66.66666666666666%;
            }

             .col-lg-7 {
                  width: 58.333333333333336%;
             }

             .col-lg-6 {
                  width: 50%;
             }

             .col-lg-5 {
                  width: 41.66666666666667%;
             }

             .col-lg-4 {
                  width: 33.33333333333333%;
             }

             .col-lg-3 {
                  width: 25%;
             }

             .col-lg-2 {
                    width: 16.666666666666664%;
             }

             .col-lg-1 {
                    width: 8.333333333333332%;
              }
              .pt-4 { padding-top: 20px }
              .top-height { height: 140px }
              .mt-4 { margin-top: 20px }
              .border {
                 border: 1px solid #dee2e6 !important;
                 border-width: 1px !important;
                  border-style: solid !important;
                  border-color: #DB4237 !important;
                  font-size: 10px !important;
                  background-color: !important;
                  /*padding:0px;*/
                 -webkit-print-color-adjust:exact !important;
               }
              .border-bottom {
                  border-bottom: 1px solid #dee2e6 !important;
                  border-bottom-color: rgb(222, 226, 230);
              }
              .border-dark {
                  border-color: #343a40 !important;
              }
              .margin-top-0 {
                margin-top: 0 !important;
              }
              .text-center { text-align: center; }
              .margin-top-20 { margin-top: 40px; }
              .my-3 {
                margin:20px 0 20px;
              }
              .tg  {border-collapse:collapse;border-spacing:0;}
              .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
                overflow:hidden;padding:10px 5px;word-break:normal;}
              .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
                font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
              .tg .tg-0lax{text-align:left;vertical-align:top}
           }
         </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    getCustomDataZeroList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.honorariumSheet.org = orgObj.text_en
            this.honorariumSheet.org_bn = orgObj.text_bn
          } else {
            this.honorariumSheet.org = ''
            this.honorariumSheet.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.honorariumSheet.office = officeObj.text_en
            this.honorariumSheet.office_bn = officeObj.text_bn
          } else {
            this.honorariumSheet.office = ''
            this.honorariumSheet.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.honorariumSheet.fiscal_year = fiscalYearObj.text_en
            this.honorariumSheet.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.honorariumSheet.fiscal_year = ''
            this.honorariumSheet.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.honorariumSheet.training_title = trainingTitleObj.text_en
            this.honorariumSheet.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.honorariumSheet.training_title = ''
            this.honorariumSheet.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.honorariumSheet.office_type = OfficeTypeListObj.text_en
            this.honorariumSheet.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.honorariumSheet.office_type = ''
            this.honorariumSheet.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.honorariumSheet.training_type = trainingTypeObj.text_en
            this.honorariumSheet.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.honorariumSheet.training_type = ''
            this.honorariumSheet.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.honorariumSheet.training_category = trainingCategoryObj.text_en
            this.honorariumSheet.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.honorariumSheet.training_category = ''
            this.honorariumSheet.training_category_bn = ''
          }
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
